import $http from '../../utils/http.js';

// 套餐管理
export function index_package(data){
    return $http.get("/resources/menu_list",data,'loadingDiv');
}
// 资费查询
export function index_postage(data){
    return $http.post("/resources/index",data,'loadingDiv');
}

// 套餐查询
export function package_edit(data){
    return $http.post("/resources/configure",data,'loadingDiv');
}


// 企业名称查询
export function index_corp(data){
    return $http.post("/Package/index_corp",data,'loadingDiv');
}
// 套餐发送
export function send_package(data){
    return $http.post("/resources/send_menu",data,'loadingDiv');
}
// 新建套餐
export function save_package(data){
    return $http.post("/resources/create_menu",data,'loadingDiv');
}

// 新建套餐
export function edit_package(data){
    return $http.post("/resources/edit_menu",data,'loadingDiv');
}

// 套餐分类
// 分类列表
export function indexsort_package(data){
    return $http.get("/resources/classify",data,'loadingDiv');
}
// 添加分类
export function addsort_package(data){
    return $http.post("/resources/add_classify",data,'loadingDiv');
}
// 修改分类
export function editsort_package(data){
    return $http.post("/resources/edit_classify",data,'loadingDiv');
}
// 启用停用
export function enablesort_package(data){
    return $http.post("/resources/enable_classify",data,'loadingDiv');
}

// 关联功能项
export function resources_get_configure(data){
    return $http.get("/resources/get_configure",data,'loadingDiv');
}


// 删除分类
export function deletesort_package(data){
    return $http.post("/resources/delete_classify",data,'loadingDiv');
}

// 获取分类信息
export function package_class_get(data){
    return $http.get("/resources/get_classify",data,'loadingDiv');
}
// 套餐分类
export function idsort_package(data){
    return $http.get('/smclassify/get',data,'loadingDiv');
}


// 套餐配置
// 套餐读取
export function read_package(data){
    return $http.post("/Package/read_package",data,'loadingDiv');
}
// 套餐修改
export function update_package(data){
    return $http.post("/resources/enable",data,'loadingDiv');
}

//套餐停用启用
export function enable_package(data){
    return $http.post('/resources/enable_menu',data,'loadingDiv');
}

//套餐查询信息
export function get_package(data){
    return $http.get('/resources/get_menu',data,'loadingDiv');
}
// 查询服务项
export function index_config(data){
    return $http.get("/resources/get_classify_model",data,'loadingDiv');
}
// 读取服务项
export function read_config(data){
    return $http.post("/Package/read_config",data,'loadingDiv');
}
// 修改服务项
export function update_config(data){
    return $http.post("/Package/update_config",data,'loadingDiv');
}



// 客户信息 => 套餐详情
export function customermanageSetcombo(data){
    return $http.post("/customermanage/setcombo",data,'loadingDiv');
}
