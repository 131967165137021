<template>
    <!-- 用户信息 -->
    <div class="packagedetails">
        <el-row>
            <el-col :span="24" style="margin-top: 30px">
                <el-form size="small" :inline="true" :model="formInline" class="demo-form-inline"
                    style="display: flex; justify-content: space-between">
                    <!-- <el-col :span="16">
                        <el-form-item label="名称">
                            <el-input v-model="formInline.input" placeholder="请输入内容"></el-input>
                        </el-form-item>
                        <el-form-item label="状态">
                            <el-select style="width: 120px" v-model="formInline.user" placeholder="职位状态">
                                <el-option label="所有" value="2"></el-option>
                                <el-option label="启用" value="0"></el-option>
                                <el-option label="停用" value="1"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-button style="margin-left: 10px" @click="init()" type="primary" size="small">
                            查询
                        </el-button>
                    </el-col> -->
                    <el-col :span="4" :offset="20" style="text-align: right">
                        <span></span>
                        <!-- 权限 -->
                        <!-- :disabled="!permissionsAction.add" -->
                        <el-button style="height: 32px !important" type="primary" size="small" @click="editClick()"
                            class="koaz">新建分类</el-button>
                    </el-col>
                </el-form>
            </el-col>
            <div style="width: 100%; margin-top: 20px">
                <el-table :header-cell-style="{ background: '#F2F2F2' }" :data="tableData" border
                    style="width: 100%; text-align: center">
                    <el-table-column width="60" label="序号" align="center" type="index" :index="indexMethod">
                    </el-table-column>
                    <el-table-column width="200" align="center" prop="classify_number" label="编号"></el-table-column>
                    <el-table-column align="center" prop="classify_name" label="名称"></el-table-column>
                    <el-table-column align="center" prop="create_time" label="创建时间"></el-table-column>

                    <el-table-column align="center" label="操作">
                        <template #default="scope">
                            <!-- :disabled="!permissionsAction.edit" -->
                            <el-button @click="editClick(scope.row.id)" type="text" size="small">编辑</el-button>
                            <!-- :disabled="!permissionsAction.enable" -->
                            <el-button @click="disableClick(scope.row.id, scope.row.status)" type="text" size="small">{{
                            scope.row.status == 1 ? '停用' : '启用'
                            }}</el-button>
                            <!-- :disabled="!permissionsAction.send" -->
                            <el-button type="text" size="small" @click="deletesort(scope.row.id)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="text-align: center; margin-top: 25px">
                    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="page.page" :page-sizes="[10, 15, 30, 35]" :page-size="page.size"
                        layout="total, sizes, prev, pager, next, jumper" :total="page.totalItems">
                    </el-pagination>
                </div>
            </div>
        </el-row>
    </div>

    <el-dialog v-model="packFlag" title="新建/编辑分类" width="800px" center :before-close="handleClose">
        <span>
            <el-form ref="form" class="packagedetails" :model="formOne" size="small" label-width="140px">
                <el-form-item label="分类名称：">
                    <el-input v-model="formOne.ClassifyName" style="width: 300px"></el-input>
                </el-form-item>
                <el-form-item label="关联功能项" style="
                        display: flex;
                        align-items: center;
                        height: 40px;
                        margin: 0;
                        border-bottom: 1px solid #ebeff0;
                        color: #cdcef3;
                    "></el-form-item>
                <el-checkbox-group v-model="formOne.type">
                    <div style="
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            flex-wrap: wrap;
                            padding: 0 100px;
                            margin: 0;
                            color: #cdcef3;
                        ">
                        <el-checkbox :label="item.id" name="type" v-for="(item, index) in p_c" :key="index">{{
                        item.module_name
                        }}</el-checkbox>
                    </div>
                </el-checkbox-group>
                <el-form-item style="text-align: center">
                    <el-button type="primary" @click="onSubmit()">保存</el-button>
                    <el-button @click="handleClose">取消</el-button>
                </el-form-item>
            </el-form>
        </span>
    </el-dialog>
</template>
<script>
import {
    indexsort_package, // 分类列表
    addsort_package, // 添加分类
    editsort_package, // 修改分类
    enablesort_package, // 启用停用
    deletesort_package, // 删除分类
    package_class_get,
    resources_get_configure
} from '../../common/js/package';
export default {
    components: {},
    data() {
        return {
            // 管理编辑切换控制
            packFlag: false,
            // 套餐管理数据
            // 套餐编辑id
            edit_id: '',
            // 搜索数据
            value1: '',
            value2: '',
            formInline: {
                user: '2',
                input: ''
            },
            // 搜索下拉框内容
            tablezhiwei: [
                {
                    id: 0,
                    label: '所有'
                },
                {
                    id: 1,
                    label: '企业管理员'
                }
            ],
            // 表格数据
            tableData: [],
            // 分页
            page: {
                page: 1, // 当前页
                size: 10,
                totalItems: 0
            },
            // 套餐基本信息
            formOne: {
                id: '',
                ClassifyName: '',
                type: []
            },
            // 套餐配置
            p_c: [''],
            // 权限操作集合
            permissionsAction: {}
        };
    },
    methods: {
        handleClose() {
            this.formOne = {
                id: '',
                ClassifyName: '',
                type: []
            };
            this.packFlag = false;
        },
        indexMethod(index) {
            // return this.page.totalItems * (this.page.page - 1) + 1 + index;
            return this.page.size * (this.page.page - 1) + 1 + index;
        },
        // 编辑套餐
        editClick(value) {
            resources_get_configure().then(re => {
                this.p_c = re.result;
                if (value) {
                    package_class_get({ id: value }).then(res => {
                        // console.log(res.result);
                        this.formOne = {
                            id: res.result.classify.id,
                            ClassifyName: res.result.classify.classify_name,
                            type: res.result.relation
                        };
                        this.init();
                        // console.log(this.formOne);
                    });
                }
                this.packFlag = true;
            });
        },
        // 停用套餐
        disableClick(id, value) {
            let actionName = value == 1 ? '停用' : '启用';
            this.$confirm('', `确认${actionName}此分类？`, {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                dangerouslyUseHTMLString: true,
                type: 'warning',
                center: true
            }).then(() => {
                enablesort_package({ id: id, status: value == 0 ? 1 : 0 }).then(res => {
                    if (res.code == 200) {
                        this.$message.success(res.msg);
                        this.init();
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            });
        },
        // 删除套餐分类
        deletesort(value) {
            this.$confirm('', `确认删除此分类？`, {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                dangerouslyUseHTMLString: true,
                type: 'warning',
                center: true
            }).then(() => {
                deletesort_package({ id: value, status: -1 }).then(res => {
                    if (res.code == 200) {
                        this.$message.success(res.msg);
                        this.init();
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            });
        },
        // 分页
        handleSizeChange(size) {
            this.page.size = size;
            this.page.page = 1;
            this.init();
        },
        handleCurrentChange(page) {
            this.page.page = page;
            this.init();
        },
        init() {
            let dt = {
                page: this.page.page,
                size: this.page.size
            };
            indexsort_package(dt).then(res => {
                if (res.code == 200) {
                    this.tableData = res.result.data;
                    this.page.totalItems = res.result.total;
                    // this.permissionsAction = res.attach.Power;
                }
            });
        },
        // 保存
        onSubmit() {
            if (!this.formOne.ClassifyName) {
                this.$message.error('请输入分类名称');
                return;
            }
            if (this.formOne.type.length == 0) {
                this.$message.error('请选择关联功能项');
                return;
            }
            if (this.formOne.id) {
                // 套餐基本信息
                editsort_package({
                    id: this.formOne.id,
                    classify_name: this.formOne.ClassifyName,
                    configure_ids: this.formOne.type
                }).then(res => {
                    this.$message.success(res.msg);
                    this.handleClose();
                    this.init();
                });
            } else {
                addsort_package({
                    id: this.formOne.id,
                    classify_name: this.formOne.ClassifyName,
                    configure_ids: this.formOne.type
                }).then(res => {
                    // console.log(res);
                    this.$message.success(res.msg);
                    this.handleClose();
                    this.init();
                });
            }
        }
    },
    mounted() {
        this.init();
    }
};
</script>
<style>
.packagedetails .koaz {
    width: 100px;
    height: 30px;
    border-radius: 4px;
    background-color: #1790ff;
    color: white;
    text-align: center;
    margin: 0 auto;
    font-size: 14px;
    cursor: pointer;
}

.packagedetails .el-checkbox {
    width: 260px;
    margin: 20px 0 !important;
}

.packagedetails .el-form-item__content {
    margin: 0 !important;
}

.packagedetails .el-form-item--small .el-form-item__label {
    padding-left: 25px;
}

.packagedetails .el-checkbox__label {
    display: inline-block;
    padding-left: 10px;
    line-height: 19px;
    font-size: var(--el-checkbox-font-size);
    word-break: normal;
    /*width: auto;*/
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow: hidden;
    vertical-align: top;
}
</style>
